window.Mode = {

    current: 'home', 

    begin: function (next) {

        var cond = (window.Mode.current === 'home' && next === 'page') || (window.Mode.current === 'page' && next === 'home');

        if (cond) {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        }

        window.Mode.current = next;
    },

    end: function (callback) {
        setTimeout(function () {
            callback();
        }, 500);
    },

    page: function () {

        window.Mode.begin('page')

        $('#myCarousel').css('height', '450px');
        $('#myCarousel > ol').css('opacity', '0').css('pointer-events', 'none');
        $('#myCarousel > div.carousel-inner > div.item > div.carousel-caption').css('opacity', '0').css('pointer-events', 'none');
        $('#myCarousel > div.page-caption').css('opacity', '1').css('pointer-events', 'all');

        window.Mode.end(function () {
            $('#myCarousel > div.carousel-inner > div.item > div.fill').css('background-attachment', 'fixed').css('height', '100%');
        });

    },

    home: function () {

        window.Mode.begin('home')
 
        $('#myCarousel').css('height', '100%');
        $('#myCarousel > ol').css('opacity', '1').css('pointer-events', 'all');
        $('#myCarousel > div.carousel-inner > div.item > div.carousel-caption').css('opacity', '1').css('pointer-events', 'all');
        $('#myCarousel > div.page-caption').css('opacity', '0').css('pointer-events', 'none');

        window.Mode.end(function () {
            $('#myCarousel > div.carousel-inner > div.item > div.fill').css('background-attachment', 'initial').css('height', '100vh');
        });

    }
};
window.recomputePriceValues = function (customer) {

    var make = function () {
        $.each(window.products, function (i, product) {
            // change this based on state

            product.viewAsApprovedCompany = typeof customer !== 'undefined' && customer.approved_company;
            product.price = product.viewAsApprovedCompany ? product.price_wholesale : product.price_retail;

            if (product.discounted) {
                product.old_price = product.viewAsApprovedCompany ? product.old_price_wholesale : product.old_price_retail;
            }

                                                                                                // for mistake reasons V
            product.dph = product.tax_type === 'lower' ? config.dph : (product.tax_type === 'base' ? config.dph_big : (product.tax_type === 'zero' ? 0 : 100));

            product.price_notax = product.price / (product.dph + 100) * 100;

            product.price = product.price.toFixed(2);
            product.price_notax = product.price_notax.toFixed(2);

            if (product.discounted) {
                product.old_price = product.old_price.toFixed(2);
            }
        });

        window.Cart.init();
    };

    if (typeof window.products === 'undefined') {
        var waitingForProductsInterval = setInterval(function () {

            if (typeof window.products !== 'undefined') {
                clearInterval(waitingForProductsInterval);
                 make();
            }

        }, 5);
    } else {
         make();
    }
};

// fetch all products
$.get(config.address + 'api/products/read/all/', function (response) {

    $.each(response, function (i, product) {
        var titleParts = product.title.split(" ");
        var firstPart = titleParts.shift();
        var secondPart = titleParts.join(" ");

        product.fancy_title = '<b>' + firstPart + '</b> ' + secondPart;
        product.slug = window.generateSlug(product.title);
    });

    // natural sort
    response.sort(function(a, b) {
        var ax = [], bx = [];

        a.title.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
        b.title.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });

        while(ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if(nn) return nn;
        }

        return ax.length - bx.length;
    });

    window.products = response;
});

window.Cart = {

    render: function (cart) {

        var cartItems = [];
        var fullPrice = 0;

        $.each(cart, function (i, item) {
            $.each(window.products, function (i, product) {
                if (item.id == product.id) {
                    // make recomputions

                    var priceTimesCount = product.price * item.count;
                    var fancy_math = '<b>' + product.price + '</b> Kč x <b>' + item.count + '</b> = <b>' + priceTimesCount.toFixed(2) + '</b> Kč';

                    cartItems.push({
                        product: product,
                        count: item.count,
                        fancy_math: fancy_math
                    });

                    fullPrice += priceTimesCount;
                }
            });
        });

        // inflate
        var cartMarkup = $.templates('#toggleCartItemTemplate').render(cartItems);
        $('.toggle-cart-holder').html(cartMarkup);

        // full price
        $('.toggle-cart-full-price').text(Math.round(fullPrice));

        // badge
        $('.toggle-cart-badge').text('( ' + cartItems.length + ' )');
    },

    refresh: function (cart) {

        if (typeof window.products === 'undefined') {
            var waitingForProductsInterval = setInterval(function () {

                if (typeof window.products !== 'undefined') {
                    clearInterval(waitingForProductsInterval);
                    window.Cart.render(cart);
                }

            }, 5);
        } else {
            window.Cart.render(cart)
        }

    },

    addProduct: function (id, count) {

        var cart = window.Cookies.getJSON('cart');

        if (typeof cart === 'undefined') {
            cart = [];
        }

        var inCart = false;
        $.each(cart, function (i, item) {
            if (item.id == id) {
                item.count = parseInt(item.count) + parseInt(count);
                if (item.count < 1) item.count = 1;
                inCart = true;
            }
        });

        if (!inCart) {
            cart.push({
                id: id,
                count: count
            });
        }

        window.Cookies.set('cart', cart, {
            expires: 14
        });

        window.Cart.refresh(cart);
    },

    init: function () {
        var cart = window.Cookies.getJSON('cart');

        if (typeof cart === 'undefined') {
            cart = [];
        }

        window.Cart.refresh(cart);
    },

    removeProduct: function (id) {

        var cart = window.Cookies.getJSON('cart');

        if (typeof cart === 'undefined') {
            cart = [];
        }

        var newCart = [];
        $.each(cart, function (i, item) {
            if (item.id != id) {
                newCart.push(item);
            }
        });

        window.Cookies.set('cart', newCart, {
            expires: 14
        });

        window.Cart.refresh(newCart);
    }
};

var backToCurrentMode = null;

$('.toggle-cart-button').click(function (event) {
    event.preventDefault();

    var $toggleCart = $('div.toggle-cart');
    var insideBigCart = $(this).parent().hasClass('active');

    if (!window.toggleCartOpen) {

        if (!insideBigCart) {
            // open

            if (window.toggleSearchOpen) {
                $('.toggle-search-button').trigger('click');
            }

            $('html, body').animate({
                scrollTop: 0
            }, 500);

            window.toggleCartOpen = true;
            $toggleCart.css('top', '0').css('opacity', 1);
            backToCurrentMode = window.Mode.current;
            window.Mode.home();
        }

    } else {
        // close
        window.toggleCartOpen = false;
        $toggleCart.css('top', '-100vh').css('opacity', 0);

        if (backToCurrentMode === 'page') {
            window.Mode.page();

        } else if (backToCurrentMode === 'home') {
            window.Mode.home();
        }
    }

    return false;
});

$('.toggle-cart-close-button').click(function (event) {
    event.preventDefault();
    $('.toggle-cart-button').trigger('click');
    return false;
});

$('.toggle-cart').on('click', 'a.toggle-cart-product-controls', function (event) {
    event.preventDefault();

    var $self = $(this);
    var $toggleCartItem = $self.closest('.toggle-cart-item');

    var type = $self.data('type');
    var id = $toggleCartItem.data('id');

    if (type === 'plus') {
        window.Cart.addProduct(id, 1);

    } else if (type === 'minus') {
        window.Cart.addProduct(id, -1);

    } else if (type === 'remove') {
        window.Cart.removeProduct(id);
    }


    return false;
});

window.verboseError = function (error) {
    if (error === 'files-not-empty') return 'Pole musí obsahovat soubory.';

    if ($.isArray(error)) {
        if (error[0] === 'files-images') return 'Pole musí obsahovat pouze obrázky.';
        if (error[0].startsWith('files-images-size')) return 'Velikost obrázků v poli musí být ' + error[0].split(":")[1];
    }

    if (error.startsWith('files-min')) return 'Minimální počet souborů v poli je ' + error.split(":")[1];
    if (error.startsWith('files-max')) return 'Maximální počet souborů v poli je ' + error.split(":")[1];

    if (error.startsWith('custom')) return error.split(":")[1];

    if (error === 'not-null') return 'Pole nebylo odesláno';
    if (error === 'string') return 'Pole musí být text';
    if (error === 'integer') return 'Pole musí být celé číslo';
    if (error === 'not-empty') return 'Pole nesmí být prázdné';
    if (error.startsWith('min')) return 'Minimální počet znaků v poli je ' + error.split(":")[1];
    if (error.startsWith('max')) return 'Maximální počet znaků v poli je ' + error.split(":")[1];
    if (error.startsWith('intmin')) return 'Minimální hodnota pole je ' + error.split(":")[1];
    if (error.startsWith('intmax')) return 'Maximální hodnota pole je ' + error.split(":")[1];
    if (error === 'email') return 'Pole musí být email';

    return 'Pole má problém';
};

window.generateSlug = function(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêěìíïîõòóöôùúüûůñçžščřďťňý·/_,:;";
    var to   = "aaaaaeeeeeeiiiiooooouuuuunczscrdtny------";
    
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
};

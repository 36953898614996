window.updateUserMenu = function () {
    $.get(config.address + 'auth-token-customer/', function (response) {

        var $navItemUser = $('.nav-item-user');
        var markup;

        if (response.status === 'success') {
            markup = '<a href="#/ucet"><i class="fa fa-fw fa-user"></i> ' + response.customer.first_name + '</a>';
        } else {
            markup = '<a href="#/prihlaseni"><i class="fa fa-fw fa-user"></i></a>';
        }

        $navItemUser.html(markup);
        window.recomputePriceValues(response.customer);
    });
};

window.updateUserMenu();

var updateLink = function (name) {
    $('#nav1 > ul > li.nav-item').removeClass('active');
    $('#nav1 > ul > li.nav-item[data-name="' + name + '"]').addClass('active');
};

var inflationActive = null;
var inflationRunning = false;
var inflationOnHold = null;

var inflateView = function (name, done) {
    inflationActive = name;

	if (inflationRunning) {
		inflationOnHold = {
			name: name,
			done: done
		};
		return;
	}

	inflationRunning = true;

    $.get(config.address + 'pages/' + name + '/', function (content) {

        var $contentHolder = $('#content-holder');
        var $pageCaption = $('.page-caption');

        $contentHolder.animate({
            opacity: 0,
        }, 283, function () {

           $contentHolder.html(content.pageview);

           $contentHolder.animate({
                opacity: 1
            }, 283);

            $pageCaption.css('opacity', 0);

            setTimeout(function () {
                $pageCaption.html(content.headview);
                $pageCaption.css('opacity', 1);

				inflationRunning = false;

				if (inflationOnHold != null) {
					var holder = inflationOnHold;
					inflationOnHold = null;
					inflateView(holder.name, holder.done);
				}

            }, 283);

            eval(content.script);

		    if (done) done();

            if (window.Hook.designable.pageChanged) window.Hook.designable.pageChanged();

        });

    });
};

window.Hook = {
    shopCategoryChange: null,
    shopProductOpen: null,
    designable: {
        allowPageChange: null,
        pageChanged: null
    }
};

var stopProceed = function () {
    if (window.Hook.designable.allowPageChange && !window.Hook.designable.allowPageChange()) {
        return true;
    }

    return false;
}

var resetCartIfNeeded = function () {

    if (window.toggleCartOpen ) {
        $('.toggle-cart-button').trigger('click');
    }

    if (window.toggleSearchOpen ) {
        $('.toggle-search-button').trigger('click');
    }
};

var titleModified = false;
var baseTitle = '';

var changeTitle = function (sulfix) {
    if (!titleModified) {
        titleModified = true;
        baseTitle = document.title;
    }

    document.title = baseTitle + ' - ' + sulfix;
};

var Handle = {
    home: function () {
        if (stopProceed()) return;
        resetCartIfNeeded();
        window.Mode.home();
        inflateView('home');
        updateLink('home');
        changeTitle('Domů');
    },

    shop: function (params) {
        if (stopProceed()) return;
        resetCartIfNeeded();

        var category = params.category;

        if (inflationActive !== 'shop') {
            window.Mode.page();

            inflateView('shop', function () {
                window.Hook.shopCategoryChange(category);

                if (typeof params.product !== 'undefined') {
                    window.Hook.shopProductOpen(params.product);
                }
            });

            updateLink('shop');
            changeTitle('Obchod');

        } else {
            window.Hook.shopCategoryChange(category);

            if (typeof params.product !== 'undefined') {
                window.Hook.shopProductOpen(params.product);
            }
        }

    },

    contact: function () {
        if (stopProceed()) return;
        resetCartIfNeeded();
        window.Mode.page();
        inflateView('contact');
        updateLink('contact');
        changeTitle('Kontakt');
    },

    account: {
        index: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('account/index');
            updateLink('user');
            changeTitle('Účet');
        },

        edit: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('account/edit');
            updateLink('user');
            changeTitle('Upravit účet');
        },

        changePassword: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('account/change-password');
            updateLink('user');
            changeTitle('Změnit heslo');
        },

        logout: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            Cookies.remove('auth_token_customer');
            Cookies.remove('refresh_token_customer');
            router.navigate('/');
            window.updateUserMenu();
        }
    },

    login: {
        index: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('login/index');
            updateLink('user');
            changeTitle('Přihlášení');
        },

        lostPassword: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('login/lost-password');
            updateLink('user');
            changeTitle('Ztracené heslo');
        },
    },

    register: {
        user: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('register/user');
            updateLink('user');
            changeTitle('Uživatelská registrace');
        },

        business: function () {
            if (stopProceed()) return;
            resetCartIfNeeded();
            window.Mode.page();
            inflateView('register/business');
            updateLink('user');
            changeTitle('Obchodní registrace');
        },
    },

    cart: function () {
        if (stopProceed()) return;
        resetCartIfNeeded();
        window.Mode.page();
        inflateView('cart');
        updateLink('cart');
        changeTitle('Košík');
    },

    tac: function () {
        if (stopProceed()) return;
        resetCartIfNeeded();
        window.Mode.page();
        inflateView('tac');
        changeTitle('Obchodní podmínky');
    }

};

var initRouter = function () {

    if (window.location.hash === '' || window.location.hash === '#') {
        window.location.hash = '#/';
    }

    window.router = new window.Navigo(null, true);

    window.router.on('/ucet', Handle.account.index);
    window.router.on('/ucet/upravit', Handle.account.edit);
    window.router.on('/ucet/zmenit-heslo', Handle.account.changePassword);
    window.router.on('/ucet/odhlasit', Handle.account.logout);

    window.router.on('/obchod/:category/:product', Handle.shop);
    window.router.on('/obchod/:category', Handle.shop);

    window.router.on('/kontakt', Handle.contact);
    window.router.on('/kosik', Handle.cart);

    window.router.on('/prihlaseni/ztracene-heslo', Handle.login.lostPassword);
    window.router.on('/prihlaseni', Handle.login.index);

    window.router.on('/registrace/uzivatel', Handle.register.user);
    window.router.on('/registrace/firma', Handle.register.business);

    window.router.on('/obchodni-podminky', Handle.tac);

    window.router.on('/', Handle.home);

    window.router.notFound(function () {

    });

    window.router.resolve();
};

Pace.once('done', function () {
    initRouter();
});

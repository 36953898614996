var backToCurrentMode = null;

$('.toggle-search-button').click(function (event) {
    event.preventDefault();

    var $toggleSearch = $('div.toggle-search');

    if (!window.toggleSearchOpen) {

        // open

        if (window.toggleCartOpen) {
            $('.toggle-cart-button').trigger('click');
        }

        $('html, body').animate({
            scrollTop: 0
        }, 500, function () {
            $('input[type="text"].toggle-search-input').focus();
        });

        window.toggleSearchOpen = true;
        $toggleSearch.css('top', '0').css('opacity', 1);
        backToCurrentMode = window.Mode.current;
        window.Mode.home();

    } else {
        // close
        window.toggleSearchOpen = false;
        $toggleSearch.css('top', '-100vh').css('opacity', 0);

        if (backToCurrentMode === 'page') {
            window.Mode.page();

        } else if (backToCurrentMode === 'home') {
            window.Mode.home();
        }
    }

    return false;
});

$('.toggle-search-close-button').click(function (event) {
    event.preventDefault();
    $('.toggle-search-button').trigger('click');
    return false;
});

var processProducts = function (query, products) {
    // bad algorithm, just for testing
    var foundProducts = [];
    query = query.toLowerCase();

    $.each(products, function (i, product) {
        if (product.title.toLowerCase().indexOf(query) != -1) {
            foundProducts.push(product);
        }
    });

    return foundProducts;
};

$('input[type="text"].toggle-search-input').on('keyup', function (event) {
    var query = $(this).val();

    if (typeof window.products !== 'undefined') {
        var usingProducts = processProducts(query, window.products);
        var searchMarkup = $.templates('#toggleSearchItemTemplate').render(usingProducts);
        $('.toggle-search-holder').html(searchMarkup);
    }
});

$('.toggle-search-holder').on('click', '.toggle-search-item', function (event) {
    event.preventDefault();

    var $self = $(this);
    var id = $self.data('id');
    var slug = $self.data('slug');

    window.location.hash = '#/obchod/vsechny-produkty/' + id + '-' + slug;

    $('.toggle-search-close-button').trigger('click');

    return false;
});

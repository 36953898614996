var navbarOpen = false;
var navbarRgba;

var navbarBackground = function () {

    var height = $(window).height();

    if (window.Mode.current === 'page') {
        height = 450;   
    }

    var a = $(window).scrollTop() / height;

    a = a > 1 ? 1 : a;
    a = a < 0 ? 0 : a;
    a = a.toFixed(2);

    if (navbarOpen) {
        a = 1;
    }

    rgba = 'rgba(51, 62, 72, ' + a + ')';

    if (navbarRgba != rgba) {
        $('#header-2 > nav').css('background', rgba);
        navbarRgba = rgba;
    }

};

// main bar opacity transition
$(document).ready(function () {
    $(window).scroll(navbarBackground);
});

// smooth animation up and down
$(document).ready(function () {

    $('body').scrollspy({ target: ".navbar", offset: 50 });

    $("#myNavbar a").on('click', function (event) {
 
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({ 
                scrollTop: $(hash).offset().top
            }, 800);
        }
        
    });
});

// closeup
$(document).ready(function () {
    $('#nav1 > ul > li.nav-item > a').click(function () {
        if ($(window).width() <= 768) {
            $('#header-2 > nav > div > div.navbar-header > button').trigger('click');
        }
    });

    $('#nav1').on('hide.bs.collapse', function () {
        navbarOpen = false;
        navbarBackground();
    });

    $('#nav1').on('show.bs.collapse', function () {
        navbarOpen = true;
        navbarBackground();
    });
});
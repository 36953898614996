window.Navigo = require('navigo');
window.Cookies = require('js-cookie');
require('jsrender');

// global
window.toggleCartOpen = false;
window.toggleSearchOpen = false;

require('./modules/Functions.js');
require('./modules/Mode.js');
require('./modules/Handle.js');
require('./modules/Navbar.js');
require('./modules/Cart.js');
require('./modules/Search.js');
require('./modules/Product.js');
require('./modules/Carousel.js');
require('./modules/CookiePolicy.js');
 
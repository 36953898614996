$(document).ready(function () {

    if (Cookies.get('eu-cookies') === undefined) {
        $('.cookie-footer').css('display', 'block');
    }

    $('.agree-cookie-footer-button').click(function (event) {
        $('.cookie-footer').css('display', 'none');

        Cookies.set('eu-cookies', true, {
            expires: 365
        });

    });
});

$(document).ready(function () {
    var $items = $('#myCarousel > ol.carousel-indicators > li');
    var waitingInterval = null;

    var nextSlide = function () {
        var activeIndex;

        $items.each(function (i, li) {
            var $li = $(li);

            if ($li.hasClass('active')) {
                if (i == $items.size() - 1) {
                    activeIndex = 0;
                } else {
                    activeIndex = i + 1;
                }
            }
        });

        $($items.get(activeIndex)).trigger('click');
    };

    var reset = function () {
        if (waitingInterval != null) {
            clearInterval(waitingInterval);
            waitingInterval = null;
        }

        waitingInterval = setInterval(nextSlide, 10000);
    };

    $items.click(reset);
    reset();
});
